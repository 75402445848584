var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "saleCalendarRef"
  }, [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      disabledDate: _vm.disabledDate
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large",
            loading: _vm.loading
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large",
            loading: _vm.loading2
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c("a-button", {
    attrs: {
      type: _vm.status == -1 ? "primary" : "text",
      loading: _vm.loading3,
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.statusFilter(-1, "loading3");
      }
    }
  }, [_vm._v("全部 "), _vm.status == -1 ? _c("span", [_vm._v("(" + _vm._s(_vm.total) + ")")]) : _vm._e()]), _c("a-button", {
    attrs: {
      type: _vm.status == 0 ? "primary" : "text",
      loading: _vm.loading4,
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.statusFilter(0, "loading4");
      }
    }
  }, [_vm._v("待审核"), _vm.status == 0 ? _c("span", [_vm._v("(" + _vm._s(_vm.total) + ")")]) : _vm._e()]), _c("a-button", {
    attrs: {
      type: _vm.status == 1 ? "primary" : "text",
      loading: _vm.loading5,
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.statusFilter(1, "loading5");
      }
    }
  }, [_vm._v("通过"), _vm.status == 1 ? _c("span", [_vm._v("(" + _vm._s(_vm.total) + ")")]) : _vm._e()]), _c("a-button", {
    attrs: {
      type: _vm.status == 2 ? "primary" : "text",
      loading: _vm.loading6,
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.statusFilter(2, "loading6");
      }
    }
  }, [_vm._v("拒绝"), _vm.status == 2 ? _c("span", [_vm._v("(" + _vm._s(_vm.total) + ")")]) : _vm._e()])], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loadingComputed
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return [_c("div", {
            key: key
          }, [key == "auditstatus" ? _c("span", [_vm._v(" " + _vm._s(text == 0 ? "待审核" : text == 1 ? "通过" : "拒绝") + " ")]) : key == "releasetime" ? _c("span", [_vm._v(" " + _vm._s(!text ? "---" : _vm.$moment(text * 1000).utc().format("YYYY-MM-DD HH:mm:ss")) + " ")]) : key == "isplatformselection" ? _c("span", [_vm._v(" " + _vm._s(text == 0 ? "否" : "是") + " ")]) : key == "usernickname" ? _c("span", {
            class: {
              blue: record.useruid
            },
            on: {
              click: function click($event) {
                return _vm.toUserDetail(record.useruid);
              }
            }
          }, [_vm._v(" " + _vm._s(text || "-") + " ")]) : key == "action" ? _c("span", [record.auditstatus != 0 ? _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["salecalendaraudit:check"],
              expression: "['salecalendaraudit:check']"
            }],
            on: {
              click: function click($event) {
                return _vm.checkDetail(record);
              }
            }
          }, [_vm._v("查看 ")]) : _vm._e(), record.auditstatus == 0 ? _c("a", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["salecalendaraudit:audit"],
              expression: "['salecalendaraudit:audit']"
            }],
            on: {
              click: function click($event) {
                return _vm.editDetail(record);
              }
            }
          }, [_vm._v("审核")]) : _vm._e()]) : _c("span", [_vm._v(_vm._s(record[key] || "---"))])])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    staticClass: "modal-container",
    attrs: {
      title: "发售详情",
      width: 660,
      footer: null,
      getContainer: function getContainer() {
        return _vm.$refs.saleCalendarRef;
      },
      centered: ""
    },
    model: {
      value: _vm.visible2,
      callback: function callback($$v) {
        _vm.visible2 = $$v;
      },
      expression: "visible2"
    }
  }, [_c("a-form-model", {
    ref: "ruleForm2",
    attrs: {
      rules: _vm.rules,
      model: _vm.saleDetails,
      "label-col": {
        span: 8
      },
      "wrapper-col": {
        span: 12
      }
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "白名单发售时间(UTC)",
      prop: "booktime"
    }
  }, [_c("a-date-picker", {
    attrs: {
      "show-time": "",
      placeholder: "请选择白名单发售时间",
      "value-format": "YYYY-MM-DD HH:mm:ss",
      format: "YYYY-MM-DD HH:mm:ss",
      disabled: _vm.popType == "check"
    },
    model: {
      value: _vm.saleDetails.booktime,
      callback: function callback($$v) {
        _vm.$set(_vm.saleDetails, "booktime", $$v);
      },
      expression: "saleDetails.booktime"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "正式发售时间(UTC)",
      prop: "releasetime"
    }
  }, [_c("a-date-picker", {
    attrs: {
      "show-time": "",
      placeholder: "请选择正式发售时间",
      "value-format": "YYYY-MM-DD HH:mm:ss",
      format: "YYYY-MM-DD HH:mm:ss",
      disabled: _vm.popType == "check"
    },
    model: {
      value: _vm.saleDetails.releasetime,
      callback: function callback($$v) {
        _vm.$set(_vm.saleDetails, "releasetime", $$v);
      },
      expression: "saleDetails.releasetime"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "发售用户昵称"
    }
  }, [_c("span", {
    class: {
      blue: _vm.saleDetails.useruid
    },
    on: {
      click: function click($event) {
        return _vm.toUserDetail(_vm.saleDetails.useruid);
      }
    }
  }, [_vm._v(_vm._s(_vm.saleDetails.usernickname || "-"))])]), _c("a-form-model-item", {
    attrs: {
      label: "发售用户ID"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.saleDetails.useruid))])]), _c("a-form-model-item", {
    attrs: {
      label: "发售用户粉丝量"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.saleDetails.followers))])]), _c("a-form-model-item", {
    attrs: {
      label: "审核通过后自动进入平台精选",
      porp: "isplatformselection"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.popType == "check",
      placeholder: "请选择"
    },
    model: {
      value: _vm.saleDetails.isplatformselection,
      callback: function callback($$v) {
        _vm.$set(_vm.saleDetails, "isplatformselection", $$v);
      },
      expression: "saleDetails.isplatformselection"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 是 ")]), _c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 否 ")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "白名单发售形式"
    }
  }, [_c("a-select", {
    attrs: {
      disabled: _vm.popType == "check",
      placeholder: "请选择"
    },
    model: {
      value: _vm.saleDetails.whitelisttype,
      callback: function callback($$v) {
        _vm.$set(_vm.saleDetails, "whitelisttype", $$v);
      },
      expression: "saleDetails.whitelisttype"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 平台空投 ")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      label: "生成资格码"
    }
  }, [_vm._v(" " + _vm._s(_vm.saleDetails.invitecodenum) + " "), _c("a", {
    staticStyle: {
      "margin-left": "10px"
    },
    on: {
      click: function click($event) {
        return _vm.checkInvitecodenumDetail(_vm.saleDetails.useruid);
      }
    }
  }, [_vm._v("查看详细")])]), _vm.popType == "edit" ? _c("a-form-model-item", {
    attrs: {
      label: "审核结果",
      prop: "auditstatus"
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.popType == "check"
    },
    model: {
      value: _vm.saleDetails.auditstatus,
      callback: function callback($$v) {
        _vm.$set(_vm.saleDetails, "auditstatus", $$v);
      },
      expression: "saleDetails.auditstatus"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("通过")])]), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("拒绝")])])], 1)], 1) : _vm._e(), _c("a-form-model-item", {
    attrs: {
      label: " "
    }
  }, [_vm.popType == "edit" ? _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.loading7
    },
    on: {
      click: _vm.editSaleDetailsSubmit
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1), _c("a-modal", {
    staticClass: "code-modal-container",
    attrs: {
      title: "资格码",
      width: 360,
      footer: null,
      getContainer: function getContainer() {
        return _vm.$refs.saleCalendarRef;
      },
      centered: ""
    },
    model: {
      value: _vm.visible1,
      callback: function callback($$v) {
        _vm.visible1 = $$v;
      },
      expression: "visible1"
    }
  }, [_c("div", {
    staticClass: "code-div"
  }, _vm._l(_vm.invitecodenumList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "code-item-div"
    }, [_c("div", [_vm._v(_vm._s(item.qualificationcode))]), _c("div", {
      staticClass: "icon-div",
      on: {
        click: function click($event) {
          return _vm.copyFun(item.qualificationcode);
        }
      }
    }, [_c("a-icon", {
      attrs: {
        type: "copy"
      }
    })], 1)]);
  }), 0), _c("div", [_c("a-input", {
    attrs: {
      type: "number",
      placeholder: "Enter amount of code"
    },
    model: {
      value: _vm.codeAmount,
      callback: function callback($$v) {
        _vm.codeAmount = $$v;
      },
      expression: "codeAmount"
    }
  })], 1), _c("div", [_c("a-button", {
    staticClass: "w-100 copy-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.copyAll
    }
  }, [_vm._v("复制所有")])], 1), _c("div", [_c("a-button", {
    staticClass: "w-100",
    attrs: {
      type: "primary",
      loading: _vm.loading8
    },
    on: {
      click: _vm.createMore
    }
  }, [_vm._v("生成更多")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };